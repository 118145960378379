import {InjectionToken} from '@angular/core';

// eslint-disable-next-line import/no-cycle
import {environment} from '../../../../environments/environment';

export interface Environment {
    readonly production: boolean;
    readonly baseUrl: string;
    readonly artistUrl: string;
    readonly clientId: string;
    readonly clientSecret: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>(
    'an abstraction over environment config',
    {
        providedIn: 'root',
        factory: (): Environment => environment,
    },
);
