import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter, withComponentInputBinding} from '@angular/router';
import {INTERCEPTORS} from '@core/interceptors';
import {POOL_TEXTFIELD_PROVIDER} from '@shared/util';
import {TuiAlertModule, TuiRootModule} from '@taiga-ui/core';

import {routes} from './app-shell-routing';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(withInterceptors(INTERCEPTORS)),
        provideAnimations(),
        POOL_TEXTFIELD_PROVIDER,
        importProvidersFrom([TuiRootModule, TuiAlertModule]),
    ],
};
