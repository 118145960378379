// eslint-disable-next-line import/no-cycle
import {Environment} from '@shared/util/tokens/environment';

export const environment: Environment = {
    production: false,
    baseUrl: '/api',
    artistUrl: '/api-artists',
    clientId: 'lEZIkxCOm9391TJlB5GEsnHDxOToBe4KMQ7tE2Oa',
    clientSecret:
        'oCzm8cDnijCDYhvFII2x8zriZfcmSv5bNQc1L5xozRhOuCN5jLS0M7dK46gFFkyExCCd8esdBtB1lSeWbNRXSH7EDHgDKieLjjOEhecdkvVZrr4jngbUwJMpe2e3g8l3',
};
