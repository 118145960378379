import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthStore} from '@auth/data-access-auth';
import {RouterLinks} from '@shared/util';
import {safeToSignal} from '@shared/util/safe-to-signal';

export function authGuard(): boolean {
    const router = inject(Router);
    const auth = inject(AuthStore);
    const isAuthorized = safeToSignal(auth.isAuthorized$, false);

    if (!isAuthorized()) {
        void router.navigateByUrl(`/${RouterLinks.Auth}/${RouterLinks.Login}`);
    }

    return isAuthorized();
}
