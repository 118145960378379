export enum RouterLinks {
    Main = '',
    Home = 'home',

    Auth = 'auth',
    Login = 'login',
    ResetPassword = 'reset-password',
    SendEmailPassword = 'send-email-password',

    OppReturn = 'opp-return',

    Verify = 'verify',
    Agreements = 'agreements',
    Steps = 'steps',
    PersonalData = 'personal-data',
    Questionnaire = 'questionnaire',
    Identification = 'identification',
}
