import {Injectable} from '@angular/core';
import {BaseEntity, ListResponse} from '@shared/model/interfaces';

import {BaseCrudApi} from './base-crud.api';

type GeoPaths = 'countries' | 'states' | 'cities';

@Injectable({providedIn: 'root'})
export class GeoApi extends BaseCrudApi<ListResponse<BaseEntity>, GeoPaths> {
    protected readonly suffix = 'geo';
    readonly paths = ['countries', 'states', 'cities'] as const;
}
