import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Releases} from '@shared/model/interfaces';
import {ENVIRONMENT} from '@shared/util/tokens/environment';
import {Observable, pluck} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ReleasesApi {
    private readonly _http = inject(HttpClient);
    private readonly _environment = inject(ENVIRONMENT);

    getReleases(): Observable<Releases[]> {
        return this._http
            .get<Releases[]>(`${this._environment.artistUrl}/releases/`)
            .pipe(pluck('results')) as Observable<Releases[]>;
    }
}
