import {Injectable} from '@angular/core';
import {PersonalData} from '@verify/model';

import {BaseCrudApi} from './base-crud.api';

@Injectable({providedIn: 'root'})
export class PersonalDataApi extends BaseCrudApi<PersonalData> {
    protected readonly suffix = 'personal-data';
    readonly paths = [];
}
