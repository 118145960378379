import {HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthState, initialAuthState, isExpiredToken} from '@auth/data-access-auth/auth';
import {errMessage} from '@core/util';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
// eslint-disable-next-line import/no-cycle
import {AuthApi} from '@shared/data-access';
import {ResetPasswordApi} from '@shared/data-access/api/reset-password.api';
import {SignInParams, Token, UserPassword} from '@shared/model/interfaces/auth';
import {ResetPassword} from '@shared/model/interfaces/auth/reset-password.interface';
import {RouterLinks} from '@shared/util';
import {ACCESS_TOKEN_STORAGE_KEY} from '@shared/util/constants/access-token';
import {LOCAL_STORAGE} from '@shared/util/tokens/local-storage';
import {TuiAlertService, TuiNotification} from '@taiga-ui/core';
import {agreementsKeys} from '@verify/util';
import {filter, finalize, Observable, of, switchMap, tap, throwError} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthStore extends ComponentStore<AuthState> {
    private readonly _initAuthState = this.effect($ =>
        $.pipe(
            switchMap(() => {
                const token = this._localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

                if (!token) {
                    return of(null);
                }

                return of(JSON.parse(token) as Token);
            }),
            tap(token => this._updateToken(token)),
        ),
    );

    private readonly _handleAlert = this.effect<{
        message: string;
        status: TuiNotification;
    }>(params$ =>
        params$.pipe(
            switchMap(({message, status}) => {
                this._setLoading(false);

                return this._alerts.open(message, {status});
            }),
        ),
    );

    readonly signIn = this.effect((credentials$: Observable<SignInParams>) =>
        credentials$.pipe(
            tap(() => this._setLoading(true)),
            switchMap((credentials: SignInParams) =>
                this._authApi.getToken(credentials).pipe(
                    tapResponse(
                        (token: Token) => {
                            this._updateToken(token);
                            void this._router.navigateByUrl(RouterLinks.Home);
                        },
                        (error: HttpErrorResponse) => {
                            this._handleAlert({
                                message: errMessage(error),
                                status: TuiNotification.Error,
                            });
                        },
                    ),
                ),
            ),
        ),
    );

    readonly signOut = this.effect($ =>
        $.pipe(
            switchMap(() => of(this.get().token)),
            filter(Boolean),
            tap(() => this._setLoading(true)),
            switchMap((token: Token) =>
                this._authApi.revokeToken(token.accessToken).pipe(
                    tapResponse(
                        () => {
                            this._updateToken(null);
                            void this._router.navigateByUrl(
                                `/${RouterLinks.Auth}/${RouterLinks.Login}`,
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this._handleAlert({
                                message: errMessage(error),
                                status: TuiNotification.Error,
                            });
                        },
                    ),
                ),
            ),
        ),
    );

    readonly resetPassword = this.effect((resetPassword$: Observable<ResetPassword>) =>
        resetPassword$.pipe(
            tap(() => this._setLoading(true)),
            switchMap((resetPassword: ResetPassword) =>
                this._resetPasswordApi.resetPassword(resetPassword).pipe(
                    tapResponse(
                        () => {
                            this._handleAlert({
                                message:
                                    'Password recovery email is on the way. Please check your email.',
                                status: TuiNotification.Success,
                            });

                            void this._router.navigateByUrl(
                                `/${RouterLinks.Auth}/${RouterLinks.Login}`,
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this._handleAlert({
                                message: errMessage(error),
                                status: TuiNotification.Error,
                            });
                        },
                    ),
                    finalize(() => this._setLoading(false)),
                ),
            ),
        ),
    );

    readonly setPassword = this.effect((userPassword$: Observable<UserPassword>) =>
        userPassword$.pipe(
            tap(() => this._setLoading(true)),
            switchMap((userPassword: UserPassword) =>
                this._resetPasswordApi.setPassword(userPassword).pipe(
                    tapResponse(
                        () => {
                            this._handleAlert({
                                message: 'Your password successfully updated.',
                                status: TuiNotification.Success,
                            });

                            void this._router.navigateByUrl(
                                `/${RouterLinks.Auth}/${RouterLinks.Login}`,
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this._handleAlert({
                                message: errMessage(error),
                                status: TuiNotification.Error,
                            });
                        },
                    ),
                    finalize(() => this._setLoading(false)),
                ),
            ),
        ),
    );

    readonly isLoading$ = this.select(state => state.isLoading);
    readonly isAuthorized$ = this.select(state => state.isAuthorized);
    readonly token$ = this.select(state => state.token);

    constructor(
        @Inject(Router) private readonly _router: Router,
        @Inject(AuthApi) private readonly _authApi: AuthApi,
        @Inject(ResetPasswordApi) private readonly _resetPasswordApi: ResetPasswordApi,
        @Inject(TuiAlertService) private readonly _alerts: TuiAlertService,
        @Inject(LOCAL_STORAGE) private readonly _localStorage: Storage,
    ) {
        super(initialAuthState);
        this._initAuthState();
    }

    refreshToken$(): Observable<Token> {
        const {token} = this.get();

        if (!token) {
            return throwError(new Error('Token is not defined'));
        }

        return this._authApi
            .refreshToken(token.refreshToken)
            .pipe(tap((tokenResponse: Token) => this._updateToken(tokenResponse)));
    }

    private _setLoading(isLoading: boolean): void {
        this.patchState({isLoading});
    }

    private _updateToken(token: Token | null): void {
        if (!token) {
            this.patchState(initialAuthState);
            this._localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
            Object.keys(agreementsKeys).forEach((key: string) =>
                this._localStorage.removeItem(key),
            );

            return;
        }

        if (!token?.expireDate) {
            token.expireDate = new Date().valueOf() * 1000 + (token?.expiresIn || 0);
        }

        this.patchState({
            isLoading: false,
            isAuthorized: !isExpiredToken(token),
            token,
        });

        this._localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, JSON.stringify(token));
    }
}
