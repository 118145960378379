import {Token} from '@shared/model/interfaces/auth';

export interface AuthState {
    isLoading: boolean;
    isAuthorized: boolean;
    token: Token | null;
}

export const initialAuthState: AuthState = {
    isLoading: false,
    isAuthorized: false,
    token: null,
};

export function isExpiredToken(token: Token | null): boolean {
    if (!token || !token.expireDate) {
        return true;
    }

    return new Date().valueOf() > token.expireDate;
}
