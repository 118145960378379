import {HttpClient, HttpHeaders} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {SignInParams, Token} from '@shared/model/interfaces/auth';
import {ENVIRONMENT} from '@shared/util/tokens/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthApi {
    private readonly _http = inject(HttpClient);
    private readonly _environment = inject(ENVIRONMENT);

    getToken(credentials: SignInParams): Observable<Token> {
        const formData: FormData = new FormData();

        formData.append('username', credentials.username);
        formData.append('password', credentials.password);
        formData.append('client_id', this._environment.clientId);
        formData.append('client_secret', this._environment.clientSecret);
        formData.append('grant_type', 'password');

        const headers = new HttpHeaders({
            enctype: 'multipart/form-data',
        });

        return this._http.post<Token>(
            `${this._environment.artistUrl}/auth/token/`,
            formData,
            {headers},
        );
    }

    revokeToken(accessToken: string): Observable<Response> {
        const formData: FormData = new FormData();

        formData.append('token', accessToken);
        formData.append('client_id', this._environment.clientId);

        const headers = new HttpHeaders({
            enctype: 'multipart/form-data',
        });

        return this._http.post<Response>(
            `${this._environment.artistUrl}/auth/logout/`,
            formData,
            {headers},
        );
    }

    refreshToken(refreshToken: string): Observable<Token> {
        const formData: FormData = new FormData();

        formData.append('refresh_token', refreshToken);
        formData.append('client_id', this._environment.clientId);
        formData.append('client_secret', this._environment.clientSecret);
        formData.append('grant_type', 'refresh_token');

        const headers = new HttpHeaders({
            enctype: 'multipart/form-data',
        });

        return this._http.post<Token>(
            `${this._environment.artistUrl}/auth/token/`,
            formData,
            {headers},
        );
    }
}
