import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {QueryParameters} from '@shared/model/interfaces';
import {ENVIRONMENT} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable()
export abstract class BaseCrudApi<T, K = never> {
    abstract readonly paths: readonly K[];
    protected abstract readonly suffix: string;

    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(ENVIRONMENT);

    get(): Observable<T> {
        return this.http.get<T>(`${this.environment.baseUrl}/${this.suffix}/`);
    }

    getAll<U>(params?: U): Observable<T> {
        return this.http.get<T>(`${this.environment.baseUrl}/${this.suffix}/`, {
            params: params as Record<string, never>,
        });
    }

    getById(id: number | string): Observable<T> {
        return this.http.get<T>(`${this.environment.baseUrl}/${this.suffix}/${id}/`);
    }

    getByPath(path: K, params: Partial<QueryParameters> = {}): Observable<T> {
        return this.http.get<T>(`${this.environment.baseUrl}/${this.suffix}/${path}/`, {
            params,
        });
    }

    getByPathAndId<U>(
        id: number,
        path: K,
        params: Partial<QueryParameters> = {},
    ): Observable<U> {
        return this.http.get<U>(
            `${this.environment.baseUrl}/${this.suffix}/${path}/${id}`,
            {params},
        );
    }

    create<U>(body: U): Observable<T> {
        return this.http.post<T>(`${this.environment.baseUrl}/${this.suffix}/`, body);
    }

    update<U>(body: U): Observable<T> {
        return this.http.patch<T>(`${this.environment.baseUrl}/${this.suffix}/`, body);
    }

    deleteByPath(path: typeof this.paths[number], id: number | string): Observable<T> {
        return this.http.delete<T>(
            `${this.environment.baseUrl}/${this.suffix}/${path}/${id}/`,
        );
    }
}
