import {TuiDay} from '@taiga-ui/cdk';

export const allowedDateRange = {
    min: getAllowedDate(120),
    max: getAllowedDate(18),
};

function getAllowedDate(subtractYears: number): TuiDay {
    const currentDate = new Date();

    currentDate.setFullYear(currentDate.getFullYear() - subtractYears);

    return new TuiDay(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
    );
}
