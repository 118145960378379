import {computed, Injectable, signal} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SpinnerService {
    private readonly visible = signal(false);

    readonly isVisible = computed(() => this.visible());

    open(): void {
        this.visible.set(true);
    }

    close(): void {
        this.visible.set(false);
    }
}
