import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import {computed, inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {AuthStore} from '@auth/data-access-auth';
import {Token} from '@shared/model/interfaces/auth';
import {ENVIRONMENT} from '@shared/util';
import {Observable, switchMap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

export function AuthorizationInterceptorFn(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
    const authStore = inject(AuthStore);
    const token = toSignal(authStore.token$);
    const environment = inject(ENVIRONMENT);
    const accessToken = computed(() => {
        if (!token()) {
            return '';
        }

        return token()!.accessToken;
    });

    if (!req.url.includes(environment.baseUrl || environment.artistUrl)) {
        return next(req);
    }

    const requestWithBearer = addBearerToken(req, accessToken());

    return next(requestWithBearer).pipe(
        catchError((error: unknown) => {
            if ((error as HttpErrorResponse).status === 401) {
                return handleRefreshToken(req, next, authStore);
            }

            return throwError(() => error);
        }),
    );
}

function addBearerToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    if (!token) {
        return req;
    }

    const setHeaders = {Authorization: `Bearer ${token || ''}`};

    return req.clone({setHeaders});
}

function handleRefreshToken(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn,
    authStore: AuthStore,
): Observable<HttpEvent<unknown>> {
    return authStore.refreshToken$().pipe(
        switchMap((token: Token) => next(addBearerToken(req, token.accessToken))),
        catchError((error: unknown) => {
            authStore.signOut();

            return throwError(() => error);
        }),
    );
}
