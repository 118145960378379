import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Verification} from '@shared/model/interfaces';
import {ENVIRONMENT} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VerificationApi {
    protected readonly http = inject(HttpClient);
    protected readonly environment = inject(ENVIRONMENT);

    start(): Observable<Verification> {
        return this.http.post<Verification>(
            `${this.environment.baseUrl}/verification/start/`,
            {},
        );
    }

    startOpp(): Observable<Verification> {
        return this.http.get<Verification>(
            `${this.environment.baseUrl}/verification/opp/`,
        );
    }
}
