import {Provider} from '@angular/core';
import {TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TUI_TEXTFIELD_SIZE} from '@taiga-ui/core';

export const POOL_TEXTFIELD_PROVIDER: Provider[] = [
    {
        provide: TUI_TEXTFIELD_APPEARANCE_DIRECTIVE,
        useValue: {
            appearance: 'pool-textfield',
        },
    },
    {
        provide: TUI_TEXTFIELD_SIZE,
        useValue: {
            size: 's',
        },
    },
];
