import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {TuiRootModule} from '@taiga-ui/core';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, TuiRootModule],
    template: `
        <tui-root>
            <router-outlet></router-outlet>
        </tui-root>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {}
