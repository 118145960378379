import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ResetPassword, UserPassword} from '@shared/model/interfaces';
import {ENVIRONMENT} from '@shared/util/tokens/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ResetPasswordApi {
    private readonly _http = inject(HttpClient);
    private readonly _environment = inject(ENVIRONMENT);

    resetPassword(body: ResetPassword): Observable<ResetPassword> {
        return this._http.post<ResetPassword>(
            `${this._environment.artistUrl}/auth/password/reset/send/`,
            body,
        );
    }

    setPassword(body: UserPassword): Observable<UserPassword> {
        return this._http.post<UserPassword>(
            `${this._environment.artistUrl}/auth/password/reset/`,
            body,
        );
    }
}
