import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {ArtistProfile} from '@shared/model/interfaces/profile';
import {ArtistProfileUpdate} from '@shared/model/types';
import {ENVIRONMENT} from '@shared/util';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ArtistProfileApi {
    private readonly _http = inject(HttpClient);
    private readonly _environment = inject(ENVIRONMENT);

    artistProfile(): Observable<ArtistProfile> {
        return this._http.get<ArtistProfile>(`${this._environment.artistUrl}/artist/`);
    }

    updateArtistProfile(body: ArtistProfileUpdate): Observable<ArtistProfile> {
        return this._http.put<ArtistProfile>(
            `${this._environment.artistUrl}/artist/`,
            body,
        );
    }
}
