import {Routes} from '@angular/router';
import {authGuard} from '@core/guards/auth.guard';
import {RouterLinks} from '@shared/util';

export const routes: Routes = [
    {
        path: RouterLinks.OppReturn,
        loadComponent: () => import('@success/feature').then(x => x.SuccessPageComponent),
    },

    {
        path: RouterLinks.Auth,
        loadChildren: async () => import('@auth/feature/auth-shell/auth-shell-routing'),
    },

    {
        path: '',
        canMatch: [authGuard],
        loadChildren: async () => import('@main/feature/main-shell/main-shell-routing'),
    },

    {
        path: '**',
        redirectTo: '',
    },
];
