import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {MonthStatisticParams, Statistics} from '@shared/model/interfaces';
import {ENVIRONMENT} from '@shared/util/tokens/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class StatisticsApi {
    private readonly _http = inject(HttpClient);
    private readonly _environment = inject(ENVIRONMENT);

    getStatistics(): Observable<Statistics> {
        return this._http.get<Statistics>(
            `${this._environment.artistUrl}/artist/statistics/`,
        );
    }

    downloadMonthStatistic(params: MonthStatisticParams): Observable<Blob> {
        const httpParams = new HttpParams({fromObject: {...params}});

        return this._http.get(
            `${this._environment.artistUrl}/artist/statistics/download/`,
            {params: httpParams, responseType: 'blob'},
        );
    }
}
