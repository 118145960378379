import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {RouterLinks} from '@shared/util';
import {LOCAL_STORAGE} from '@shared/util/tokens/local-storage';
import {agreementsKeys} from '@verify/util/agreements-keys';

export function agreementsGuard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
): boolean {
    const storage = inject(LOCAL_STORAGE);
    const router = inject(Router);

    const agreements = Object.keys(agreementsKeys);
    const hasAgreed = agreements.map(key => storage.getItem(key)).every(Boolean);

    if (hasAgreed && state.url.includes(RouterLinks.Agreements)) {
        void router.navigateByUrl(`/${RouterLinks.Verify}/${RouterLinks.Steps}`);

        return false;
    }

    if (!hasAgreed && state.url.includes(RouterLinks.Steps)) {
        void router.navigateByUrl(`/${RouterLinks.Verify}/agreements`);

        return false;
    }

    return true;
}
