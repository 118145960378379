import {HttpErrorResponse} from '@angular/common/http';
import {objectToCamel} from '@lightech-llc/case-converter';

export function errMessage(error: HttpErrorResponse): string {
    const message = error.error?.error_description
        ? error.error?.error_description
        : error.error?.detail || '';

    return objectToCamel(message);
}
